import { IProduct } from "../types/IProduct";


export const PRODUCT_TYPE = "PRODUCT_TYPE";

export interface ProductAction {
  type: string;
  payload: IProduct | null;
}

export const ProductReducer = (state: IProduct | null = null, action: ProductAction): IProduct | null => {
  switch(action.type) {

    case PRODUCT_TYPE:
      return action.payload;

    default: 
      return state;
  }
};
