import React, { useState, useEffect } from "react";
import { IProductContent } from "../../types/IProductContent";
import ReactCountryFlag from "react-country-flag";
import { config } from "../../config/config";
import ProductTranslatorComponent from "../ProductTranslatorComponent/ProductTranslatorComponent";
import { useSelector } from "react-redux";
import { AppState } from "../../store/AppState";

// const ProductContentComponent = (props: IProductContent) => {
const ProductContentComponent = () => {
  
  // const product = props.product;
  const product = useSelector((state: AppState) => state.product);


  return (

      <section id="product-content" className="border-b pb-10 mb-10 border-grey-500">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-5 text-center">
          {config.srcLanguage}&nbsp;<ReactCountryFlag countryCode={config.srcLanguage} svg className="drop-shadow-md" style={{ height: "30px", width: "50px" }} />
        </div>
        <div className="col-span-2 text-center"> =&gt; </div>
        <div className="col-span-5 text-center">
          {config.dstLanguage}&nbsp;<ReactCountryFlag countryCode={config.dstLanguage} svg className="drop-shadow-md" style={{ height: "30px", width: "50px" }} />
        </div>
      </div>

      <div className="grid grid-cols-1">
        <div className="col-span-12 mb-5">
        </div>
      </div>


      <ProductTranslatorComponent 
        name="Nazwa"
        elementType="text"
        srcText={product?.name[1]}
        dstText={product?.name[2]}
        fieldName="name"
        key={product?.id + "-name"}
        />

      { product?.ps_attribute_lang.map((atr) => {
          return (
            <ProductTranslatorComponent 
              key={product?.id + "-" + atr?.id_attribute}
              name="Atrybut"
              elementType="text"
              srcText={atr?.name}
              dstText={atr?.name}
              fieldName={"ps_attribute_lang|name:id_attribute:" + atr?.id_attribute}
              />      
          );
      }) }

      <ProductTranslatorComponent 
        name="Opis"
        elementType="textarea"
        srcText={product?.description[1]}
        dstText={product?.description[2]}
        fieldName="description"
        key={product?.id + "-description"}
        />
        
      <ProductTranslatorComponent 
        name="META Opis"
        elementType="textarea"
        srcText={product?.meta_description[1]}
        dstText={product?.meta_description[2]}
        fieldName="meta_description"
        key={product?.id + "-meta_description"}
        />

    </section>      

  );
};

export default ProductContentComponent;
