// import axios from "axios";
// import React, { useState, useEffect } from "react";
// import { config } from "../config/config";
// import { IProduct } from "./../types/IProduct";
// import { IProgress } from "./../types/IProgress";
// import LoadingComponent from "./../components/LoadingComponent/LoadingComponent";
// import ProductHeaderComponent from "../components/ProductHeaderComponent/ProductHeaderComponent";
// import ProductContentComponent from "../components/ProductContentComponent/ProductContentComponent";
// import ReactCountryFlag from "react-country-flag";
// import { useDispatch } from "react-redux";
// import { PRODUCT_TYPE } from "../store/ProductReducer";
// import { PRODUCT_TRANSLATED_TYPE } from "../store/ProductTranslatedReducer";
// import { useSelector } from "react-redux";
// import { AppState } from "../store/AppState";

import React from "react";
import { Link } from "react-router-dom";

const Mainpage = () => {
  return (
    <>
      <div className="content">
        <div className="w-full h-screen text-center uppercase text-xs font-medium mt-1 pt-4 flex items-center justify-center">
          <Link to='/products' className="px-4 py-2 font-semibold text-sm bg-sky-500 text-white rounded-none shadow-sm hover:bg-sky-800 mx-5">Tłumaczenie produktów</Link>
          <Link to='/categories' className="px-4 py-2 font-semibold text-sm bg-sky-500 text-white rounded-none shadow-sm hover:bg-sky-800 mx-5">Tłumaczenie kategorii</Link>
          <Link to='/translator' className="px-4 py-2 font-semibold text-sm bg-sky-500 text-white rounded-none shadow-sm hover:bg-sky-800 mx-5">Tłumaczenie HTML / text</Link>
        </div>
      </div>
    </>
  );
};

export default Mainpage;
