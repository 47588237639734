import { ICategory } from "../types/ICategory";


export const CATEGORY_TRANSLATED_TYPE = "CATEGORY_TRANSLATED_TYPE";
export const CATEGORY_TRANSLATED_TYPE_UPDATE = "CATEGORY_TRANSLATED_TYPE_UPDATE";

export interface CategoryTranslatedAction {
  type: string;
  payload: ICategory | null;
}

const updateCategoryTranslated = (fieldName: string, value: any) => ({
  type: CATEGORY_TRANSLATED_TYPE_UPDATE,
  payload: {
    fieldName: value
  }
});

export const CategoryTranslatedReducer = (state: ICategory | null = null, action: CategoryTranslatedAction): ICategory | null => {
  switch(action.type) {

    case CATEGORY_TRANSLATED_TYPE:
      console.log(action.payload);
      return action.payload;

    case CATEGORY_TRANSLATED_TYPE_UPDATE:
      
      const newState = state;
      const keyToUpdate = String(Object.keys(action.payload as {})[0]);
      const valueToUpdate = String(Object.values(action.payload as {})[0]);

      // string|number type
      for(const key in newState) {
        if(key === keyToUpdate) {
          newState[key]  = valueToUpdate as any;
        }
      }

      return newState;

    default: 
      return state;
  }
};
