import axios from "axios";
import React, { useState, useEffect } from "react";
import { MdGTranslate } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import { config } from "../../config/config";
import "./HtmlTranslatorComponent.css";


const HtmlTranslatorComponent = () => {

  const [srcText, setSrcText] = useState("");
  const [dstText, setDstText] = useState("");
  const [translateInProgress, setTranslateInProgress] = useState(false);




  const [reload, setReload] = useState(0);

  useEffect(() => {
    setSrcText("");
    setDstText("");
    // console.log(srcText);
    // console.log(dstText);
  }, []);



  async function translateText(textToTranslate: string) {
    setTranslateInProgress(true);

    
    const translatedData = await axios
      .post(config.endpointTranslatorHtml, {
        srcHtml: textToTranslate,
        srcLang: config.srcLanguage,
        destLang: config.dstLanguage,
      })
      .then((response) => {
        if (response.data.translatedHtml !== undefined) {
          setDstText(response.data.translatedHtml);

        }
        setTranslateInProgress(false);
      })
      .catch((error) => {
        console.log(error);
        setTranslateInProgress(false);
      });
  }

  const handleTranslateClick = () => {
    translateText(srcText);
  };

  const onChangeSrcText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSrcText(e.target.value);
  };

  const onChangeDstText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDstText(e.target.value);
  };

  return (
    <div className="grid grid-cols-12 gap-4 my-7 border-b pb-10 mb-10 border-neutral-200">
      <div className="col-span-5 text-center">
        <div className="pt-3 text-left">
          <h4 className="w-full">
            <span className="bg-white px-3 text-sm font-bold uppercase">Źródło</span>
          </h4>
        </div>
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="srcText"
              cols={30}
              rows={30}
              onChange={onChangeSrcText}
              defaultValue=""
            ></textarea>
            <div className="pt-3 text-left">
              <h4 className="w-full">
                <span className="bg-white px-3 text-xs font-medium">PODGLĄD:</span>
              </h4>
              <div className="previewhtml-wrapper text-left border border-slate-300 p-5">{<div dangerouslySetInnerHTML={{ __html: srcText }} />}</div>
            </div>
      </div>
      <div className="col-span-2 flex flex-col justify-center items-center">
        {translateInProgress ? (
          <button
            className="inline-flex items-center px-6 py-4 font-semibold 
                text-xl leading-6 text-sm shadow rounded-md text-grey
                border border-gray-200
                hover:bg-slate-800 hover:text-white"
            disabled
          >
            <FaSpinner className="animate-spin" />
          </button>
        ) : (
          <button
            onClick={handleTranslateClick}
            className="inline-flex items-center px-6 py-4 font-semibold 
                text-xl leading-6 text-sm shadow rounded-md text-grey
                border border-gray-200
                hover:bg-slate-800 hover:text-white"
          >
            <MdGTranslate />
          </button>
        )}
      </div>
      <div className="col-span-5 text-center">
        <div className="pt-3 text-left">
          <h4 className="w-full">
            <span className="bg-white px-3 text-sm font-bold uppercase">Wynik</span>
          </h4>
        </div>
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="dstText"
              cols={30}
              rows={30}
              value={dstText}
              // defaultValue={dstText}
              onChange={onChangeDstText}
            ></textarea>
            <div className="pt-3 text-left">
              <h4 className="w-full">
                <span className="bg-white px-3 text-xs font-medium">PODGLĄD:</span>
              </h4>
              <div className="previewhtml-wrapper text-left border border-slate-300 p-5">{<div dangerouslySetInnerHTML={{ __html: dstText }} />}</div>
            </div>

      </div>
      {/* <div>
        <br/>
        <br/>
        {productTranslated?.id}
        <br/>
        {product?.id}
      </div> */}
    </div>
  );
};

export default HtmlTranslatorComponent ;
