import React, { useState, useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import { config } from "../../config/config";
import CategoryTranslatorComponent from "../CategoryTranslatorComponent/CategoryTranslatorComponent";
import { useSelector } from "react-redux";
import { AppState } from "../../store/AppState";



const CategoryContentComponent = () => {

  const category = useSelector((state: AppState) => state.category);


  return (
    <section id="category-content" className="border-b pb-10 mb-10 border-grey-500">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-5 text-center">
          {config.srcLanguage}&nbsp;<ReactCountryFlag countryCode={config.srcLanguage} svg className="drop-shadow-md" style={{ height: "30px", width: "50px" }} />
        </div>
        <div className="col-span-2 text-center"> =&gt; </div>
        <div className="col-span-5 text-center">
          {config.dstLanguage}&nbsp;<ReactCountryFlag countryCode={config.dstLanguage} svg className="drop-shadow-md" style={{ height: "30px", width: "50px" }} />
        </div>
      </div>

      <div className="grid grid-cols-1">
        <div className="col-span-12 mb-5">
        </div>
      </div>


      <CategoryTranslatorComponent 
        name="Nazwa"
        elementType="text"
        srcText={category?.name[1]}
        dstText={category?.name[2]}
        fieldName="name"
        key={category?.id + "-name"}
        />      

      <CategoryTranslatorComponent 
        name="Opis"
        elementType="textarea"
        srcText={category?.description[1]}
        dstText={category?.description[2]}
        fieldName="description"
        key={category?.id + "-description"}
        />
        
      <CategoryTranslatorComponent 
        name="META Opis"
        elementType="textarea"
        srcText={category?.meta_description[1]}
        dstText={category?.meta_description[2]}
        fieldName="meta_description"
        key={category?.id + "-meta_description"}
        />

    </section>  
  )
}

export default CategoryContentComponent