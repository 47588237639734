import axios from "axios";
import React, { useState, useEffect } from "react";
import { config } from "../config/config";
import { IProduct } from "./../types/IProduct";
import { IProgress } from "./../types/IProgress";
import LoadingComponent from "./../components/LoadingComponent/LoadingComponent";
import ProductHeaderComponent from "../components/ProductHeaderComponent/ProductHeaderComponent";
import ProductContentComponent from "../components/ProductContentComponent/ProductContentComponent";
import ReactCountryFlag from "react-country-flag";
import { useDispatch } from "react-redux";
import { PRODUCT_TYPE } from "../store/ProductReducer";
import { PRODUCT_TRANSLATED_TYPE } from "../store/ProductTranslatedReducer";
import { useSelector } from "react-redux";
import { AppState } from "../store/AppState";

const Productpage = () => {
  // const [product, setProduct] = useState({} as IProduct);
  const [progress, setProgress] = useState({} as IProgress);
  const [loading, setLoading] = useState(true);

  const product = useSelector((state: AppState) => state.product);

  const dispatch = useDispatch();

  async function fetchNextProduct() {
    setLoading(true);
    const productAjax = await axios
      .get<IProduct>(config.nextProductEndpoint)
      .then((response) => {
        if (response.data) {
          const product = response.data;
          if (product) {
            // setProduct(product);

            dispatch({
              type: PRODUCT_TYPE,
              payload: product,
            });

            dispatch({
              type: PRODUCT_TRANSLATED_TYPE,
              payload: {
                id: product.id,
                name: product.name[config.dstLanguageID],
                product_cover: product.product_cover,
                url: product.url,
                description: product.description[config.dstLanguageID],
                ps_attribute_group_lang: product.ps_attribute_group_lang,
                ps_attribute_lang: product.ps_attribute_lang,
                meta_description: product.meta_description[config.dstLanguageID],
              },
            });

            fetchProgress();
          }
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });

    setLoading(false);
    // setTimeout(() => { setLoading(false) }, 25000);
  }

  async function fetchProgress() {
    const progressAjax = await axios.get<IProgress>(config.progressProductsEndpoint);
    const progress = progressAjax.data;
    if (progress) {
      setProgress(progress);
    }
  }

  useEffect(() => {
    fetchNextProduct();
  }, []);

  // fetchNextProduct();

  return (
    <>
      {loading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <LoadingComponent />
        </div>
      ) : (
        <div className="content">
          <div className="w-full text-center uppercase text-xs font-medium mt-1">Postęp tłumaczenia produktów</div>
          <div className="w-full bg-emerald-50 mb-4 mt-1">
            <div className="bg-emerald-500 text-xs font-medium text-gray-700 text-center p-0.5 leading-none " style={{ width: progress.percent }}>
              {progress.percent}
            </div>
          </div>

          <div className=" border-b pb-2 pt-2 border-grey-500 w-full h-full flex flex-col justify-center items-center grid grid-cols-12 gap-4">
            <div className="col-span-6">
              <a className="text-xs" href={config.pageToTranslateUrl} target="_blank">
                {config.pageToTranslateUrl}
              </a>
            </div>
            <div className="col-span-6 text-right">
              <span className="text-xs mr-2">{config.srcLanguage}</span>
              <ReactCountryFlag countryCode={config.srcLanguage} svg className="drop-shadow-md" /> =&gt;{" "}
              <ReactCountryFlag countryCode={config.dstLanguage} svg className="drop-shadow-md" />
              <span className="text-xs ml-2">{config.dstLanguage}</span>
            </div>
          </div>
          <div className="pt-10">
            {/* <ProductHeaderComponent id={product.id} name_1={product.name[1]} name_2={product.name[2]} img_thumb={product.product_cover} /> */}
            {/* <ProductHeaderComponent product={product} getNextProductFN={fetchNextProduct} /> */}
            <ProductHeaderComponent getNextProductFN={fetchNextProduct} setLoading={setLoading} />
            <ProductContentComponent />
            {/* <ProductContentComponent product={product} /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Productpage;
