import { ICategory } from '../types/ICategory';

export const CATEGORY_TYPE = "CATEGORY_TYPE";

export interface CategoryAction {
  type: string,
  payload: ICategory | null;
}

export const CategoryReducer = (state: ICategory | null = null, action: CategoryAction): ICategory | null => {
  switch(action.type) {

    case CATEGORY_TYPE:
      return action.payload;

    default: 
      return state;
  }
}