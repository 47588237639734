import axios from "axios";
import React, { useState, useEffect } from "react";
import { MdGTranslate } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import { ITranslator } from "../../types/ITranslator";
import { config } from "../../config/config";
import "./CategoryTranslatorComponent.css";
import { useSelector } from "react-redux";
import { AppState } from "../../store/AppState";
import { ICategory } from "../../types/ICategory";
import { useDispatch } from "react-redux";
import { CATEGORY_TRANSLATED_TYPE_UPDATE } from "../../store/CategoryTranslatedReducer";

const CategoryTranslatorComponent = (props: ITranslator) => {

  const [srcText, setSrcText] = useState(props.srcText || "");
  const [dstText, setDstText] = useState(props.dstText || "");
  const [translateInProgress, setTranslateInProgress] = useState(false);

  const dispatch = useDispatch();
  const categoryTranslated = useSelector((state: AppState) => state.categoryTranslated);
  const category = useSelector((state: AppState) => state.category);


  const [reload, setReload] = useState(0);

  useEffect(() => {
    setSrcText(props.srcText || "");
    setDstText(props.dstText || "");
    // console.log(srcText);
    // console.log(dstText);
    console.log('category: ', category);
  }, []);

  useEffect(() => {
    // console.log('TranslatorComponent effect ', reload);
    // console.log('TranslatorComponent state ', product);
    setReload(reload+1);
  }, [category]);



  async function translateText(textToTranslate: string) {
    setTranslateInProgress(true);

    
    const translatedData = await axios
      .post(config.endpointTranslatorHtml, {
        srcHtml: textToTranslate,
        srcLang: config.srcLanguage,
        destLang: config.dstLanguage,
      })
      .then((response) => {
        if (response.data.translatedHtml !== undefined) {
          setDstText(response.data.translatedHtml);
          
          if(props.fieldName) {


            dispatch({
              type: CATEGORY_TRANSLATED_TYPE_UPDATE,
              payload: {
                [props.fieldName]: response.data.translatedHtml,
              }
            });
            

            

            // type ObjectKey = keyof typeof IProduct;
            // const key = props.fieldName;
            // productTranslated?['name'][2] = response.data.translatedHtml;
            // console.log(productTranslated?.['name'][2]);
          }
        }
        setTranslateInProgress(false);
      })
      .catch((error) => {
        console.log(error);
        setTranslateInProgress(false);
      });
  }

  const handleTranslateClick = () => {
    translateText(srcText);
  };

  const onChangeDstText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDstText(e.target.value);
  };

  return (
    <div className="grid grid-cols-12 gap-4 my-7 border-b pb-10 mb-10 border-neutral-200">
      <div className="col-span-5 text-center">
        <div className="pt-3 text-left">
          <h4 className="w-full">
            <span className="bg-white px-3 text-sm font-bold uppercase">[{category?.id}] {props.name}:</span>
          </h4>
        </div>
        {props.elementType === "text" ? (
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            id="srcText"
            disabled
            value={srcText}
          />
        ) : (
          <>
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="srcText"
              cols={30}
              rows={10}
              disabled
              value={srcText}
            ></textarea>
            <div className="pt-3 text-left">
              <h4 className="w-full">
                <span className="bg-white px-3 text-xs font-medium">PODGLĄD:</span>
              </h4>
              <div className="previewhtml-wrapper text-left border border-slate-300 p-5">{<div dangerouslySetInnerHTML={{ __html: srcText }} />}</div>
            </div>
          </>
        )}
      </div>
      <div className="col-span-2 flex flex-col justify-center items-center">
        {translateInProgress ? (
          <button
            className="inline-flex items-center px-6 py-4 font-semibold 
                text-xl leading-6 text-sm shadow rounded-md text-grey
                border border-gray-200
                hover:bg-slate-800 hover:text-white"
            disabled
          >
            <FaSpinner className="animate-spin" />
          </button>
        ) : (
          <button
            onClick={handleTranslateClick}
            className="inline-flex items-center px-6 py-4 font-semibold 
                text-xl leading-6 text-sm shadow rounded-md text-grey
                border border-gray-200
                hover:bg-slate-800 hover:text-white"
          >
            <MdGTranslate />
          </button>
        )}
      </div>
      <div className="col-span-5 text-center">
        <div className="pt-3 text-left">
          <h4 className="w-full">
            <span className="bg-white px-3 text-sm font-bold uppercase">[{category?.id}] {props.name}:</span>
          </h4>
        </div>
        {props.elementType === "text" ? (
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            id="dstText"
            value={dstText}
            // defaultValue={dstText}
            onChange={onChangeDstText}
          />
        ) : (
          <>
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="dstText"
              cols={30}
              rows={10}
              value={dstText}
              // defaultValue={dstText}
              onChange={onChangeDstText}
            ></textarea>
            <div className="pt-3 text-left">
              <h4 className="w-full">
                <span className="bg-white px-3 text-xs font-medium">PODGLĄD:</span>
              </h4>
              <div className="previewhtml-wrapper text-left border border-slate-300 p-5">{<div dangerouslySetInnerHTML={{ __html: dstText }} />}</div>
            </div>
          </>
        )}
      </div>
      {/* <div>
        <br/>
        <br/>
        {productTranslated?.id}
        <br/>
        {product?.id}
      </div> */}
    </div>
  );
};

export default CategoryTranslatorComponent;
