import React, { useState } from "react";
import { ICategoryHeader } from "../../types/ICategoryHeader";
import ReactCountryFlag from "react-country-flag";
import { config } from "../../config/config";
import { useSelector } from "react-redux";
import { AppState } from "../../store/AppState";
import axios from "axios";

const CategoryHeaderComponent = (props: ICategoryHeader) => {

  const category = useSelector((state: AppState) => state.category);
  const categoryTranslated = useSelector((state: AppState) => state.categoryTranslated);

  const getNextCategoryFN = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.getNextCategoryFN();
  };

  const clickHandlerSaveCategory = async () => {
    props.setLoading(true);
    const savedCategory = await axios
      .post(config.saveCategoryEndpoint, {
        category: categoryTranslated,
      }, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded'
        }
      })
      .then((response) => {
        // console.log("resp: ", response);
        if(response.data.status === 'ok') {
          props.getNextCategoryFN();
        }
      })
      .catch((error) => {
        console.log("e: ", error);
      });
    
      props.setLoading(false);
  };

  return (
    <header id="category-header" className="grid grid-cols-12 gap-4 border-b pb-10 mb-10 border-grey-500">
      <div className="col-span-10 w-full h-full flex flex-col justify-center items-start">
        <p>ID: {category?.id}</p>
        <h1 className="text-3xl font-bold mt-2">
          <ReactCountryFlag countryCode={config.srcLanguage} svg className="drop-shadow-md" />
          &nbsp; {category?.name[1]}
        </h1>
        <h3 className="text-2xl">
          <small className="text-sm font-normal">
            (<ReactCountryFlag countryCode={config.dstLanguage} svg className="drop-shadow-md" />
            &nbsp; {category?.name[2]})
          </small>
        </h3>
      </div>
      <div className="col-span-2 w-full h-full flex flex-col justify-center items-center">

        <button onClick={getNextCategoryFN} className="w-full mb-5 px-4 py-2 font-semibold text-sm bg-emerald-500 text-white rounded-none shadow-sm hover:bg-emerald-800">
          Wczytaj kategorię do tłumaczenia
        </button>
        <button onClick={clickHandlerSaveCategory} className="w-full mb-5 px-4 py-2 font-semibold text-sm bg-sky-500 text-white rounded-none shadow-sm hover:bg-sky-800">
          Zapisz zmiany
        </button>
      </div>
    </header>
  )
}

export default CategoryHeaderComponent