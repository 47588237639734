import React, { useState } from "react";
import { IProductHeader } from "../../types/IProductHeader";
import ReactCountryFlag from "react-country-flag";
import { config } from "../../config/config";
import { useSelector } from "react-redux";
import { AppState } from "../../store/AppState";
import axios from "axios";

const ProductHeaderComponent = (props: IProductHeader) => {
  // const product = props.product;

  const product = useSelector((state: AppState) => state.product);
  const productTranslated = useSelector((state: AppState) => state.productTranslated);

  const getNextProductFN = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.getNextProductFN();
  };

  const clickHandlerSaveProduct = async () => {
    props.setLoading(true);
    const savedProduct = await axios
      .post(config.saveProductEndpoint, {
        product: productTranslated,
      }, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded'
        }
      })
      .then((response) => {
        // console.log("resp: ", response);
        if(response.data.status === 'ok') {
          props.getNextProductFN();
          // setTimeout(() => { props.getNextProductFN() }, 30000);
        }
      })
      .catch((error) => {
        console.log("e: ", error);
      });
    
      props.setLoading(false)
      // setTimeout(() => { props.setLoading(false) }, 30000);
  };

  return (
    <header id="product-header" className="grid grid-cols-12 gap-4 border-b pb-10 mb-10 border-grey-500">
      <div className="col-span-1">
        <img className="w-full" src={product?.product_cover} alt={product?.name[1]} />
      </div>
      <div className="col-span-9 w-full h-full flex flex-col justify-center items-start">
        <p>ID: {product?.id}</p>
        <h1 className="text-3xl font-bold mt-2">
          <ReactCountryFlag countryCode={config.srcLanguage} svg className="drop-shadow-md" />
          &nbsp; {product?.name[1]}
        </h1>
        <h3 className="text-2xl">
          <small className="text-sm font-normal">
            (<ReactCountryFlag countryCode={config.dstLanguage} svg className="drop-shadow-md" />
            &nbsp; {product?.name[2]})
          </small>
        </h3>
      </div>
      <div className="col-span-2 w-full h-full flex flex-col justify-center items-center">
        <a
          href={product?.url}
          target="_blank"
          className="text-center w-full mb-5 px-4 py-2 font-semibold text-sm bg-slate-500 text-white rounded-none shadow-sm hover:bg-slate-800"
        >
          Pokaż ten produkt
        </a>
        <button onClick={getNextProductFN} className="w-full mb-5 px-4 py-2 font-semibold text-sm bg-emerald-500 text-white rounded-none shadow-sm hover:bg-emerald-800">
          Wczytaj produkt do tłumaczenia
        </button>
        <button onClick={clickHandlerSaveProduct} className="w-full mb-5 px-4 py-2 font-semibold text-sm bg-sky-500 text-white rounded-none shadow-sm hover:bg-sky-800">
          Zapisz zmiany
        </button>
      </div>
    </header>
  );
};

export default ProductHeaderComponent;
