import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Mainpage from "./pages/Mainpage";
import Productpage from './pages/Productpage';
import Categorypage from './pages/Categorypage';
import Translatorpage from './pages/Translatorpage';

function App() {
  return (
    <BrowserRouter>
      <div className="App">

        <div className="container 2xl mx-auto content-wrapper">
          <Routes>
            <Route path="/" element={<Mainpage />} />
            <Route path="/products" element={<Productpage />} />
            <Route path="/categories" element={<Categorypage />} />
            <Route path="/translator" element={<Translatorpage />} />
          </Routes>
        </div>

      </div>
    </BrowserRouter>
  );
}

export default App;
