import axios from "axios";
import React, { useState, useEffect } from "react";
import { config } from "../config/config";
import ReactCountryFlag from "react-country-flag";
import HtmlTranslatorComponent from "../components/HtmlTranslatorComponent/HtmlTranslatorComponent";

const Translatorpage = () => {
  return (
    <div className="content">
      <div className=" border-b pb-2 pt-2 border-grey-500 w-full h-full flex flex-col justify-center items-center grid grid-cols-12 gap-4">
      <div className="col-span-6">
        <a className="text-xs" href={config.pageToTranslateUrl} target="_blank">
          {config.pageToTranslateUrl}
        </a>
      </div>
      <div className="col-span-6 text-right">
        <span className="text-xs mr-2">{config.srcLanguage}</span>
        <ReactCountryFlag countryCode={config.srcLanguage} svg className="drop-shadow-md" /> =&gt;{" "}
        <ReactCountryFlag countryCode={config.dstLanguage} svg className="drop-shadow-md" />
        <span className="text-xs ml-2">{config.dstLanguage}</span>
      </div>
    </div>

    <div className="pt-10">
      <HtmlTranslatorComponent />
    </div>                      
  </div>
  )
}

export default Translatorpage