
export const config = {
  nextProductEndpoint: String(process.env.REACT_APP_GET_NEXT_PRODUCT_ENDPOINT),
  nextCategoryEndpoint: String(process.env.REACT_APP_GET_NEXT_CATEGORY_ENDPOINT),

  progressProductsEndpoint: String(process.env.REACT_APP_GET_PROGRESS_PRODUCTS_ENDPOINT),
  progressCategoriesEndpoint: String(process.env.REACT_APP_GET_PROGRESS_CATEGORIES_ENDPOINT),

  saveProductEndpoint: String(process.env.REACT_APP_SAVE_PRODUCT_ENDPOINT),
  saveCategoryEndpoint: String(process.env.REACT_APP_SAVE_CATEGORY_ENDPOINT),

  srcLanguage: String(process.env.REACT_APP_SRC_LANGUAGE),
  dstLanguage: String(process.env.REACT_APP_DST_LANGUAGE),

  pageToTranslateUrl: String(process.env.REACT_APP_URL_PAGE_TO_TRANSLATE_URL),
  endpointTranslatorHtml: String(process.env.REACT_APP_ENDPOINT_TRANSLATOR_HTML),
  dstLanguageID: Number(process.env.REACT_APP_DST_LANGUAGE_ID),
};
