import { combineReducers } from "redux";
import { ProductReducer } from "./ProductReducer";
import { ProductTranslatedReducer } from "./ProductTranslatedReducer";
import { CategoryReducer } from "./CategoryReducer";
import { CategoryTranslatedReducer } from "./CategoryTranslatedReducer";

export const rootReducer = combineReducers({
  product: ProductReducer,
  productTranslated: ProductTranslatedReducer,
  category: CategoryReducer,
  categoryTranslated: CategoryTranslatedReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
