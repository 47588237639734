import axios from "axios";
import React, { useState, useEffect } from "react";
import { config } from "../config/config";
import { ICategory } from "./../types/ICategory";
import { IProgress } from "./../types/IProgress";
import LoadingComponent from "./../components/LoadingComponent/LoadingComponent";
import ReactCountryFlag from "react-country-flag";
import { useDispatch } from "react-redux";
import { CATEGORY_TYPE } from "../store/CategoryReducer";
import { CATEGORY_TRANSLATED_TYPE } from "../store/CategoryTranslatedReducer";
import { useSelector } from "react-redux";
import { AppState } from "../store/AppState";
import CategoryHeaderComponent from "../components/CategoryHeaderComponent/CategoryHeaderComponent";
import CategoryContentComponent from "../components/CategoryContentComponent/CategoryContentComponent";

const Categorypage = () => {
  const [progress, setProgress] = useState({} as IProgress);
  const [loading, setLoading] = useState(true);

  const category = useSelector((state: AppState) => state.category);

  const dispatch = useDispatch();

  async function fetchNextCategory() {
    setLoading(true);
    const categoryAjax = await axios
      .get<ICategory>(config.nextCategoryEndpoint)
      .then((response) => {
        if (response.data) {
          const category = response.data;
          if (category) {
            // setProduct(product);

            dispatch({
              type: CATEGORY_TYPE,
              payload: category,
            });

            dispatch({
              type: CATEGORY_TRANSLATED_TYPE,
              payload: {
                id: category.id,
                name: category.name[config.dstLanguageID],
                description: category.description[config.dstLanguageID],
                meta_description: category.meta_description[config.dstLanguageID],
              },
            });

            fetchProgress();
          }
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });

    setLoading(false);
  }

  async function fetchProgress() {
    const progressAjax = await axios.get<IProgress>(config.progressCategoriesEndpoint);
    const progress = progressAjax.data;
    if (progress) {
      setProgress(progress);
    }
  }

  useEffect(() => {
    fetchNextCategory();
  }, []);

  return (
    <>
      {loading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <LoadingComponent />
        </div>
      ) : (
        <div className="content">
          <div className="w-full text-center uppercase text-xs font-medium mt-1">Postęp tłumaczenia kategorii</div>
            <div className="w-full bg-emerald-50 mb-4 mt-1">
              <div className="bg-emerald-500 text-xs font-medium text-gray-700 text-center p-0.5 leading-none " style={{ width: progress.percent }}>
                {progress.percent}
              </div>
            </div>

            <div className=" border-b pb-2 pt-2 border-grey-500 w-full h-full flex flex-col justify-center items-center grid grid-cols-12 gap-4">
            <div className="col-span-6">
              <a className="text-xs" href={config.pageToTranslateUrl} target="_blank">
                {config.pageToTranslateUrl}
              </a>
            </div>
            <div className="col-span-6 text-right">
              <span className="text-xs mr-2">{config.srcLanguage}</span>
              <ReactCountryFlag countryCode={config.srcLanguage} svg className="drop-shadow-md" /> =&gt;{" "}
              <ReactCountryFlag countryCode={config.dstLanguage} svg className="drop-shadow-md" />
              <span className="text-xs ml-2">{config.dstLanguage}</span>
            </div>
          </div>

          <div className="pt-10">
            <CategoryHeaderComponent getNextCategoryFN={fetchNextCategory} setLoading={setLoading} />
            <CategoryContentComponent />
          </div>                      
        </div>
      )}
    </>
  );
};

export default Categorypage;
