import { IProduct } from "../types/IProduct";


export const PRODUCT_TRANSLATED_TYPE = "PRODUCT_TRANSLATED_TYPE";
export const PRODUCT_TRANSLATED_TYPE_UPDATE = "PRODUCT_TRANSLATED_TYPE_UPDATE";

export interface ProductTranslatedAction {
  type: string;
  payload: IProduct | null;
}

const updateProductTranslated = (fieldName: string, value: any) => ({
  type: PRODUCT_TRANSLATED_TYPE_UPDATE,
  payload: {
    fieldName: value
  }
});

export const ProductTranslatedReducer = (state: IProduct | null = null, action: ProductTranslatedAction): IProduct | null => {
  switch(action.type) {

    case PRODUCT_TRANSLATED_TYPE:
      console.log(action.payload);
      return action.payload;

    case PRODUCT_TRANSLATED_TYPE_UPDATE:
      
      const newState = state;
      const keyToUpdate = String(Object.keys(action.payload as {})[0]);
      const valueToUpdate = String(Object.values(action.payload as {})[0]);
      const keyData = keyToUpdate.split('|');
      if(keyData.length > 1) {
        // array type
        const arrayKeyAndId = keyData[1].split(':');
        for(const key in newState) {
          if(key === keyData[0]) {
            const newArray = newState[key];
            for(const keyArray in newArray) {
              for(const keyDeep in newArray[keyArray]) {
                if(keyDeep === arrayKeyAndId[1] && newArray[keyArray][keyDeep] === arrayKeyAndId[2]) {
                  newArray[keyArray][arrayKeyAndId[0]] = valueToUpdate;
                }
              }
            }
          }
        }
      } else {
        // string|number type
        for(const key in newState) {
          if(key === keyToUpdate) {
            newState[key]  = valueToUpdate as any;
          }
        }
      }
      return newState;

    default: 
      return state;
  }
};
